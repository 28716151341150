import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          back: "Voltar",
        },

        hero: {
          title: "Parece que a página não existe",
          cta: "Ir para a Home",
        },
      },
    },
    es: {
      translations: {
        index: {
          back: "Volver",
        },

        hero: {
          title: "Parece que esta página no existe",
          cta: "Volver a la home",
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          back: "Volver",
        },

        hero: {
          title: "Parece que esta página no existe",
          cta: "Volver a la home",
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
