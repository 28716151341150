import "../assets/css/tailwind.css";
import "../assets/css/core.css";
import React from "react";
import { useMediaQuery } from "react-responsive";
import feature1 from "../assets/img/Feature1.svg";
import feature1Desktop from "../assets/img/Feature1-desktop.svg";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/LanguageContext";

const NotfoundComponent = () => {

  // location.href = "https://assist-365.com/404";
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isDesktop = useMediaQuery({ maxWidth: 0 });
  let url = window.location.href;
  const geo = url.split("/")[3] || "ar";
  return isDesktop ? (
    <div className="home-container bg-[#EFF1F4] h-screen  ">
      <h2 className="text-center text-3xl font-bold mx-8 pt-20 pb-6 text-[#2454AD]">
        Contenido únicamente disponible en mobile <br />
        cambia a una resolución inferior a 425px
      </h2>
    </div>
  ) : (
    // *** MOBILE

    <div className="home-container bg-[#EFF1F4]">
      <section className="home-features  max-w-[630px] xl:max-w-[1010px] mx-auto md:pt-20 ">
        <button className="mx-0" type="button">
          <div className="home-btn text-365-violet-hard text-base font-semibold mb-16 flex flex-row items-center pl-4  mt-6 gap-2 ">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                
                d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                fill="#675CE5"
              />
            </svg>
            <h3 className="text-[#675CE5]" data-dt="products_back2home">
              <a href={`/${geo}`}>{t("index.back")}</a>
            </h3>
          </div>
        </button>
        <h1 className="font-titillium text-3xl md:text-3xl xl:text-4xl text-[#2F6DE0] text-center font-bold pb-12 px-1 ">
          {t("hero.title")}
        </h1>
        <div className="feature-box-1 flex flex-col place-items-center  gap-y-4  px-4 pb-24  md:pb-24  ">
          <img src={feature1} alt="Banner" className=" md:hidden block pb-8" />
          <img
            src={feature1Desktop}
            alt="Banner"
            className="hidden md:block pb-8"
          />



          <a className="font-titillium text-base font-semibold w-[301px] h-[48px]  text-center text-[#ffffff] md:w-[343px]  justify-center  bg-[#7BD0C2] rounded-lg flex items-center"
                href={"https://assist-365.com/"}
              >
                {t("hero.cta")}
              </a>
        </div>
      </section>
    </div>
  );
};

export default NotfoundComponent;
